import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import Layout from "../components/Layout";

export const CharterPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <section className="home-hero">
      <InView threshold={0.2}>
        {({ ref, inView }) => (
          <motion.div
            ref={ref}
            initial={{ opacity: 0, transform: "translate(-50px)" }}
            animate={
              inView
                ? { opacity: 1, transform: "translate(0px)" }
                : { opacity: 1 }
            }
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
            className="ontopyo"
          >
            <h1
              data-scroll
              data-scroll-direction="horizontal"
              data-scroll-speed="2"
              data-scroll-position="top"
              data-scroll-delay="0.2"
            >
              Helicopter Charter
              <br/>
              & London Sightseeing
            </h1>
          </motion.div>
        )}
      </InView>

      <div className="scroll-line">
        <div className="center" />

        <div data-scroll data-scroll-speed="-1" className="marker" />
      </div>

      <div className="video-container">
        <div className="video-overlay" />
        <img src="../img/hero-charter.jpg" />
      </div>
    </section>
    <div className="contact-heli">
      <div className="charter-heli-grid">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="item"
            >
              <Link data-srcoll-to to="#transfers">Airport Transfers</Link>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="item"
            >
              <Link data-srcoll-to to="#events">Events</Link>
            </motion.div> 
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="item"
            >
              <Link data-srcoll-to to="#experiences">Experiences</Link>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="item"
            >
              <Link data-srcoll-to to="#private">Private Charter</Link>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="heli-grey-top" id="transfers">
      <div className="twoup">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <h2>AIRPORT TRANSFERS</h2>
              <span className="heli-line" />
              <p>
                Private jets are becoming an important tool in today’s busy
                lifestyle, saving an awful lot of wasted time on the roads,
                railways or checking-in at airport terminals. Unfortunately,
                these jets require long concrete runways, which often leave the
                passengers back on the road or rail networks to complete their
                journeys.
                <br />
                <br />
                Excel Helicopters’s fleet of twin-engine helicopters,
                together with full-time pilots enable us to offer the onward
                helicopter transfer. We can meet the passengers on the apron as
                they disembark from their jet and then take them to either The
                London Heliport, at Battersea, another airport, or on to the
                customer’s final destination.
                <br />
                <br />
                Farnborough, London Luton, RAF Northolt and Biggin Hill all
                handle private jet traffic into London, yet offer no convenient
                access into the City. With a transit time of between 10 to 20
                minutes, a helicopter transfer is a quick alternative to sitting
                in traffic.
              </p>
              <Link to="/contact">Charter a transfer</Link>
            </motion.div>
          )}
        </InView>

        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
              data-scroll data-scroll-speed="2"
            >
          
          <img src="../img/heli-London.png"/>
         
      <br/><br/>
      <br/>
              <h3>Book your helicopter tour over london</h3>

              <a href="https://www.londonpleasureflights.co.uk/" target="_blank">Book Now</a>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="heli-blue">
      <div className="twoupchart">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <div className="flight-chart">
                <div className="flight-col">
                  <div className="flight-title boldy">
                    Airports Near London
                  </div>
                  <ul className="flight-list boldy">
                    <li>Stansted</li>
                    <li>Luton</li>
                    <li>Oxford</li>
                    <li>Farnborough</li>
                    <li>Gatwick</li>
                    <li>Biggin Hill</li>
                    <li>Southend</li>
                  </ul>
                </div>
                <div className="flight-col">
                  <div className="flight-title">
                  Time to travel to Battersea
                  </div>
                  <ul className="flight-list">
                    <li>00:15</li>
                    <li>00:15</li>
                    <li>00:25</li>
                    <li>00:15</li>
                    <li>00:15</li>
                    <li>00:10</li>
                    <li>00:20</li>
                  </ul>
                </div>
                <div className="flight-col">
                  <div className="flight-title">
                  Time to travel to Heathrow
                  </div>
                  <ul className="flight-list">
                    <li>00:20</li>
                    <li>00:20</li>
                    <li>00:20</li>
                    <li>00:15</li>
                    <li>00:15</li>
                    <li>00:15</li>
                    <li>00:25</li>
                  </ul>
                </div>
              </div>
            </motion.div>
          )}
        </InView>

        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <div className="flight-chart">
                <div className="flight-col">
                  <div className="flight-title boldy">
                  Airports Further Afield
                  </div>
                  <ul className="flight-list boldy">
                    <li>Norwich</li>
                    <li>Cambridge</li>
                    <li>Peterborough</li>
                    <li>Northampton</li>
                    <li>Birmingham</li>
                    <li>Bristol</li>
                    <li>Bournemouth</li>
                    <li>Southampton</li>
                    <li>Chichester</li>
                    <li>Shoreham</li>
                    <li>Lydd</li>
                    <li>Manston</li>
                  </ul>
                </div>
                <div className="flight-col">
                  <div className="flight-title">
                  Time to travel to Battersea
                  </div>
                  <ul className="flight-list">
                    <li>00:50</li>
                    <li>00:30</li>
                    <li>00:35</li>
                    <li>00:30</li>
                    <li>00:45</li>
                    <li>00:55</li>
                    <li>00:35</li>
                    <li>00:30</li>
                    <li>00:30</li>
                    <li>00:30</li>
                    <li>00:35</li>
                    <li>00:35</li>
                  </ul>
                </div>
                <div className="flight-col">
                  <div className="flight-title">
                  Time to travel to Heathrow
                  </div>
                  <ul className="flight-list">
                    <li>00:55</li>
                    <li>00:35</li>
                    <li>00:40</li>
                    <li>00:30</li>
                    <li>00:45</li>
                    <li>00:50</li>
                    <li>00:40</li>
                    <li>00:30</li>
                    <li>00:25</li>
                    <li>00:25</li>
                    <li>00:40</li>
                    <li>00:40</li>
                  </ul>
                </div>
              </div>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="heli-white" id="events">
      <div className="twoup">
        <div className="heli-block">
          <img
            data-scroll
            data-scroll-speed="3"
            className="top75"
            src="../img/charter-img1.jpg"
          />
        </div>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <h2>EVENT TRANSFERS</h2>
              <span className="heli-line" />
              <p>
              There is nothing like sitting in a traffic jam for hours waiting to get in or out of an event to take the gloss off an occasion.  If you are attending an event as a participant, a corporate hospitality guest or as a spectator, arriving by helicopter makes the occasion even more special with the added bonus of avoiding the traffic congestion on arrival and especially on departure when everyone is trying to leave at once.
              <br/><br/>
              Excel Helicopters offer flights to motor racing, horse racing, polo, music and sailing events throughout the season.  For many of the larger events, such as the British Grand Prix at Silverstone, or for Royal Ascot, we offer a shuttle service from a designated site, and for all of the events we offer a bespoke charter service.
              </p>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="heli-grey-top">
      <div className="threeup">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <img className="iconimg" src="../img/charter-icon1.svg" />
              <h2>Horse Racing</h2>
              <span className="heli-line" />
              <p>
              Avoid traffic and chaos, book a helicopter charter directly to the racecourse
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <img className="iconimg" src="../img/charter-icon2.svg" />
              <h2>Motor Racing</h2>
              <span className="heli-line" />
              <p>
              Join us for the thrill of a helicopter flight to a race circuit & arrive with timing to suit you.
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <img className="iconimg" src="../img/charter-icon3.svg" />
              <h2>Other Events</h2>
              <span className="heli-line" />
              <p>
              Arrive in style whether it’s for Golf, Polo, Regatta or simply a fantastic day out.
              </p>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <InView threshold={0.2}>
      {({ ref, inView }) => (
        <motion.div
          ref={ref}
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 1 }}
          transition={{
            duration: 0.25,
          }}
          className="images-heli"
        >
          <div data-scroll data-scroll-speed="3" className="img50">
            <img src="../img/charter-4.jpg" />
          </div>
          <div data-scroll data-scroll-speed="-1" className="img20">
            <img src="../img/charter-3.jpg" />
          </div>
          <div data-scroll data-scroll-speed="1" className="img20">
            <img src="../img/charter-2.jpg" />
          </div>
        </motion.div>
      )}
    </InView>
    <div className="heli-grey-top top45" id="experiences">
      <div className="twoup">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block multiply"
            >
              <img src="../img/charter-5.jpg" />
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <h2>Fine Dining</h2>
              <span className="heli-line" />
              <p>
              Whether it is business, pleasure, or for that special occasion, Excel Helicopters has the answer.  ‘Heli-Dining’ is a service offered to private individuals and business persons alike, whereby a complete new dining experience can be enjoyed.  Excel Helicopters will transport you and your guests to and from any of the restaurants below for £1,700 (plus VAT), based on a maximum of five people sharing the aircraft and departing from and returning to Stapleford Aerodrome (RM4 1SJ).
              <br/><br/>
              Excel Helicopters offers the option of departing either from Stapleford Aerodrome or a private site of your choice (subject to the site being approved for helicopter operations).  A London sightseeing tour can also be included at an additional charge.  Overnight returns can also be arranged
              </p>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="heli-blue">
      <div className="twouplong">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <p>
                All of the above hotels are within 30-minutes flying time from
                our main base. The helicopter used would be a twin-engine
                Squirrel (AS355) which will wait with you whilst you enjoy your
                meal.
                <br />
                <br />
                Other requests, whether it be an overnight or weekend stay, a
                restaurant or hotel further afield, or collection from your
                property can all be arranged.
                <br />
                <br />
                Some hotels charge landing fees, which are not included
              </p>
            </motion.div>
          )}
        </InView>

        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <Link to="/contact">Contact Us</Link>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="heli-white" id="private">
      <div className="twoup">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <h2>PRIVATE HIRE</h2>
              <span className="heli-line" />
              <p>
                From short pleasure flights to large corporate events, Excel
                Charter have the range of helicopters and knowledge to cater for
                your requirements. We attend most social and sporting events
                during the Summer calendar.
                <br />
                <br />
                Hire a helicopter for the Cheltenham Festival, Royal Ascot or
                The British Grand Prix, or buy a seat on a helicopter shuttle we
                operate into these events. We can also offer helicopter charter
                flights over London and helicopter hire for weddings, corporate
                events and sightseeing tours.
              </p>
            </motion.div>
          )}
        </InView>
        <div className="heli-block">
          <img
            data-scroll
            data-scroll-speed="3"
            className="top75 rightimg"
            src="../img/charter-6.jpg"
          />
        </div>
      </div>
    </div>

    <div className="heli-grey-top" id="business">
      <div className="twoup">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <h2>BUSINESS CHARTER</h2>
              <span className="heli-line" />
              <p>
                Excel Helicopters offers a safe, cost-effective and highly
                convenient point-to-point travel option.
                <br />
                <br />
                We are all travelling further and more frequently, time spent in
                traffic is frustrating and unproductive; chartering a helicopter
                is less expensive per passenger mile than is imagined.
                <br />
                <br />
                Excel Helicopters can arrange landing sites close to your departure
                and destination. With simple boarding procedures and
                uninterrupted straight-line flight you can arrive on time and
                stress-free. Helicopter charter flights can be arranged as
                single trips or several flights enabling two or three meetings
                across the country whilst still returning home in the evening.
                Our experienced operations team can organise the helicopter
                charter to suit your itinerary.
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <h2>in Just 10 minutes</h2>
              <span className="heli-line" />
              <p>
                Our base, just 10-minutes flying time from central London,
                enables us to price competitively for airport transfers and any
                London helicopter charter.
                <br />
                <br />
                Most business jets arrive at Luton, Biggin Hill or Farnborough
                Airport, yet none of these airports have good onward road or
                rail links. With a helicopter transfer you can be met at the
                steps and taken into the London Heliport within 20-minutes.
              </p>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
  </div>
);

CharterPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const CharterPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <CharterPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

CharterPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CharterPage;

export const pageQuery = graphql`
  query CharterPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            text
          }
          heading
          description
        }
      }
    }
  }
`;
